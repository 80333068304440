import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Bridal from "./Bridal";
import Commercial from "./Commercial";
import Editorial from "./Editorial";

//Images
import portfolio_header from '../imgs/portfolio_header.webp';

const Portfolio = () => {
  
  const showBridal = () => {
    document.querySelector('#bridal').classList.remove('hidden');
    document.querySelector('#commercial').classList.add('hidden');
    document.querySelector('#editorial').classList.add('hidden');
  }

  const showCommercial = () => {
    document.querySelector('#bridal').classList.add('hidden');
    document.querySelector('#commercial').classList.remove('hidden');
    document.querySelector('#editorial').classList.add('hidden');
  }

  const showEconomy = () => {
    document.querySelector('#bridal').classList.add('hidden');
    document.querySelector('#commercial').classList.add('hidden');
    document.querySelector('#editorial').classList.remove('hidden');
  }

  return (
    <section className="font-body pt-16 md:pt-20"> 
      <Header
        img={portfolio_header}
        heading="Portfolio"
      />

      <article className="bg-portfolio-texture bg-cover bg-left md:bg-center md:bg-contain bg-no-repeat font-cta text-primary py-24 space-y-24">
        <section className="max-w-screen-2xl mx-auto">
          <h2 className="px-2 text-4xl md:text-5xl lg:text-7xl text-center font-bold tracking-widest"><em>Shine like a pearl.</em></h2>
          
          <div className="sticky z-10 top-24 my-12 md:my-24 mx-2 text-sm md:text-2xl lg:text-3xl bg-primary/90 text-secondary rounded-xl flex justify-center items-center md:gap-12">
            <button className="py-4 w-4/6 md:w-3/12 rounded-lg hover:underline underline-offset-8 hover:tracking-[2px] md:hover:tracking-[8px] transition-all" onClick={showBridal}><em>Bridal &#x2935;</em></button>

            <button className="py-4 w-4/6 md:w-3/12 rounded-lg hover:underline underline-offset-8 hover:tracking-[2px] md:hover:tracking-[8px] transition-all" onClick={showCommercial}><em>Commercial &#x2935;</em></button>

            <button className="py-4 w-4/6 md:w-3/12 rounded-lg hover:underline underline-offset-8 hover:tracking-[2px] md:hover:tracking-[8px] transition-all" onClick={showEconomy}><em>Editorial &#x2935;</em></button>
          </div>

          <div className="">
            <div id="bridal" className="">
              <Bridal/>
            </div>
            <div id="commercial" className="hidden">
              <Commercial/>
            </div>
            <div id="editorial" className="hidden">
              <Editorial />
            </div>
            
          </div>

          <div className="">
            <div loading="lazy" data-mc-src="e3cf3402-969e-492b-ac27-316426781a64#instagram" className="p-6 translate-y-16 sm:translate-y-24 md:translate-y-12"></div> 
            <div className="p-14 bg-neutral absolute w-full left-0"></div>
          </div>
        </section>
      </article>


      {/* Footer */}
    <Footer/>
    </section>
  )
};
export default Portfolio;



