import '../index.css' 
import React from "react";
import Navbar from "./Navbar"
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Route from './Route';
import Loader from './Loader';
  
const App = () => {
  return (
    <main className="bgprimary font-body">
      {/* NAVBAR */}
      <header id='navbar' className="bg-primary">
        <div className='max-w-screen-2xl mx-auto'>
          <Navbar/>
        </div>
      </header>

      <div className=''>
          <Route path="/">
            {/* <Loader/> */}
            <Home/>
          </Route>

        <Route path="/about">
            <Loader/>
            <About />
          </Route>

          <Route path="/portfolio">
            <Loader/>
            <Portfolio/>
          </Route>

          <Route path="/contact">
            <Loader/>
            <Contact/>
          </Route>
        </div>
    </main>
  );
};

export default App;