import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import TeamCard from "./TeamCard";

//Images
import about from "../imgs/about.webp";
import about_header from "../imgs/about_header.webp";
// import team from "../imgs/team.webp";

const About = () => {
  return (
    <section className="font-body pt-16 md:pt-20">
      <Header img={about_header} heading="About Us" />

      <article className="bg-about-us-texture bg-cover md:bg-contain bg-no-repeat py-24 space-y-24">
        {/* About Me Section */}
        <article className="py-24 text-primary space-y-24 lg:flex gap-24">
          <section className="max-w-screen-2xl mx-auto space-y-8 lg:flex gap-12 lg:gap-16 md:p-6">
            <div className="w-4/6 sm:w-3/6 md:w-3/6 lg:w-2/6 mx-auto md:my-auto">
              <img src={about} alt="Aishwarya" className=" rounded-md" />
            </div>

            <div className="w-5/6 md:w-4/6 mx-auto space-y-8">
              <article className="uppercase space-y-6">
                <h2 className="text-xl lg:text-2xl font-semibold text-secondary">
                  About Me
                </h2>
                <h2 className="text-4xl lg:text-6xl font-bold">
                  Aishwarya <br />{" "}
                  <span className="text-3xl lg:text-6xl">Anantharaman</span>
                </h2>
                <hr className="border border-secondary w-2/6 md:w-1/6" />
              </article>

              <article className="space-y-6 md:space-y-12 lg:w-6/6">
                <p className="opacity-60">
                  'I'm Aishwarya, a professional makeup artist. I have pursued
                  an MBA in HR. Having 6+ years of experience in the beauty
                  industry, my focus is on professionalism, quality and your
                  satisfaction. I want my clients to have the best. Since
                  childhood, makeup has always been my better half and ZAI is
                  the creation of my passion. Feeling beautiful is just another
                  step towards growing confidence. So, I have and always will
                  strive to enhance your beauty with a touch of luxury. ZAI is
                  literally an extension of my passion for makeup that has
                  helped me to portray my love for this art.'
                  <br />
                  <br />
                  Being the founder of ZAI Makeover Studio and a beauty expert,
                  it's my priority to add up to my customer's appearance. This
                  is something I have always excelled in and it makes me proud
                  to have such a great team that understands my objectives. My
                  diligence has truly rewarded me with amazing clients so far.
                  The makeup industry has made me competent to bring out the
                  colors and features of women in the most elegant and feminine
                  way. I aspire to make ZAI one of the best makeup studios and
                  make a community where beauty is appreciated.'
                </p>

                <p className="font-cta text-4xl md:text-6xl text-secondary">
                  Founder at <br /> Zai Makeover Studio
                </p>
              </article>
            </div>
          </section>
        </article>

        {/* <h2 className="text-xl lg:text-2xl font-semibold text-secondary text-center">TEAM</h2>
      
        <section className="max-w-screen-2xl mx-auto md:px-6 space-y-12 md:space-y-0 md:fex justify-center gap-6 ">

          <TeamCard
            img={team}
            name="Meghna Shashikant"
          />

        </section> */}
      </article>

      {/* Footer */}
      <Footer />
    </section>
  );
};
export default About;
