import React from "react";
import Link from "./Link";
import Service from "./Service";
import Testimonial from "./Testimonials";
import Footer from "./Footer";

// Images
import bridal from '../imgs/portfolio/bridal/img1.webp';
import commercial from '../imgs/portfolio/commercial/img1.webp';
import editorial from '../imgs/portfolio/editorial/img1.webp';
import ashwini from '../imgs/ashwini.jpeg';
import haritha from '../imgs/haritha.jpeg';
import shruthi from '../imgs/shruthi.jpeg';
import vaishnavi from '../imgs/vaishnavi.jpeg';

const Home= () => {

  return (
    <section id="landing_page" className="text-neutral  h-screen pt-16">
{/* Landing Page */}
      <article id="" className="h-screen flex flex-col justify-center items-center gap-12 text-center border-primary">
        <h2 id="headLine_one" className="font-semibold md:text-lg lg:text-xl xl:text-2xl tracking-widest">Get a classy look with Aishwarya</h2>
        <h2 id="headLine_two" className="font-thin text-4xl md:text-5xl lg:text-6xl xl:text-[6rem] uppercase tracking-widest">Light up your features</h2>

        <hr id="headLine_two" className="border w-1/6 md:w-1/12"/>

        <Link href='/contact' className="btn border-2 w-2/6 md:w-1/6 lg:w-1/12 py-2 md:py-3 font-semibold uppercase translate-y-12 hover:underline underline-offset-4 hover:tracking-[2px] transition-all">Hire Us</Link>

        <span className="btn text-center pt-2 translate-y-20">
          <i className="fa-solid fa-angle-down text-xl"></i>
        </span>
        
      </article>

{/* About Me Section */}
    <article className="bg-about-texture bgprimary bg-center md:bg-right bg-cover md:bg-contain bg-no-repeat pt-12 pb-32 text-primary space-y-24 lg:flex gap-24">
      <section className="max-w-screen-2xl mx-auto text-center space-y-6 px-2">
        <h2 className="font-semibold text-xl uppercase">"Love of beauty is taste. The creation of beauty is art." <br/> - Ralph Waldo Emerson</h2>
        <p className=" md:w-4/6 mx-auto text-lg text-primary/60">
          ZAI believes that quality is the foundation of any business. Over the years, we have taken feedback from our customers and implemented them to improve our functions. As a team, we have created a strong base for our brand and maintained the reputation for high-quality. ZAI wants to make our clients feel fabulous and appealing when they are dressed up and make memories which remain with them forever. 
        </p>
      </section>
    </article>

{/* Call To Action */}
      <article className="bg-secondary text-primary py-12">
        <section className="max-w-screen-2xl mx-auto space-y-16 md:space-y-0 md:flex justify-around">
          <div className="text-center space-y-4">
            <span className="inline-block font-cta text-6xl">6+</span>
            <hr className="w-2/6 border-primary mx-auto"/>
            <em className="inline-block text-2xl font-normal">Years of <br /> Experience</em>
          </div>
          <div className="text-center space-y-4">
            <span className="inline-block font-cta text-6xl">400+</span>
            <hr className="w-2/6 border-primary mx-auto"/>
            <em className="inline-block text-2xl font-normal">Amazing <br /> Clients</em>
          </div>
          <div className="text-center space-y-4">
            <span className="inline-block font-cta text-6xl">10+</span>
            <hr className="w-2/6 border-primary mx-auto"/>
            <em className="inline-block text-2xl font-normal">Cities <br /> Traveled</em>
          </div>
        </section>
      </article>

{/* Services */}
      <article className="bg-primary bgservices bg-center md:bg-center bg-cover bg-no-repeat py-24">
        <section className="max-w-screen-2xl mx-auto space-y-20 md:p-6">

          <div className="text-secondary text-center space-y-4 uppercase">
            <h2 className="text-xl lg:text-4xl font-semibold text-secondary">Our Services</h2>
            <hr className="border border-secondary w-1/6 md:w-1/12 mx-auto"/>
          </div>
          
          <section className="space-y-16 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center py-24">
            <Service
              img={bridal}
              head='Bridal'
            />
            <Service
              img={commercial}
              head='Commercial'
            />
            <Service
              img={editorial}
              head='Editorial'
            />
          </section>
        </section>
      </article>

{/* Testimonial*/}
      <article className="py-24 bg-testimonial-texture bg-top-right bg-contain bg-no-repeat">
        <section className="max-w-screen-2xl mx-auto space-y-20">
          <div className="text-secondary text-center space-y-4 uppercase">
            <i className="fa-solid fa-quote-left text-6xl"></i>
            <h2 className="text-xl lg:text-4xl font-semibold text-secondary">What they say</h2>
            <hr className="border border-secondary w-1/6 md:w-1/12 mx-auto"/>
          </div>
          
        <article className="md:grid grid-cols-2 justify-items-center">

          <Testimonial
            text="She is such a sweetheart and the products that she use are all branded and she is always punctual and finishes the makeup and hair before an hour to the function she is one of the best part of my wedding my darling thank you for making me look more beautiful on my big day
            Like"
            img={haritha}
            name="Haritha B"
            service="Hairstyling, Makeovers"
          />

          <Testimonial
            text="I am very much happy with your work  . All my friends and family appreciated your work. You will be my forever MUA. Your kindness and patience towards client was very much appreciatable. Makeup was very elegant and stunning especially I cannot get over my muhurtham look. Makeup stayed fresh till midnight Baba will always bless you. And convey my appreciation to Meghna. Hairstyle was very neat and prettý"
            img={vaishnavi}
            name="Vaishnavi Chandrasekar"
            service="Bridal Makeup"
            />

          <Testimonial
            text="Aishwarya is an absolutely patient and beautiful soul and that's exactly why she has the magic of making brides look their prettiest best on their big days.
            I cannot thank her enough for the different and beautiful looks she gave me for my weddings - yes two of them - north Indian and south indian looks along with the changeover for the reception.
            Each look was very distinct and exactly what I wanted. Very minimalistic, enhancing the features and making me look the naturally beautiful.
            We had time challenges and Aishu was generously the best fit to help tackle the challenges. No complaints, no hurry burry but the best experience.
            I just cannot stop going Gaga over the look and compliments I received for my wedding!!"
            img={shruthi}
            name="Shruthi Purohit"
            service="Bridal makeup"
          />
            
          <Testimonial
            text="I contacted Aishu after seeing her work on her Instagram handle. I realised that she has all the skills and professional knowledge that we can expect from a true and wonderful makeup artist the moment I saw her page. She is really good in giving a subtle and natural look. Aishu is such a wonderful person and she is so down-to-earth. She is such a doll and gets so excited like a child when she gets the right look that best suits you (she always gets the right output ;)). I am glad I had her to do both reception and wedding makeup for my brother's wedding. She chose to come up with a dewy look for reception and matte look for muhurtham. Both the looks were amazing. My reception makeup stayed fresh and best for more than 9 hours until we deliberately had to remove it to do muhurtham makeover. She did more than what was expected for the amount I paid her. I am really happy about her work. I got compliments from so many people for both the looks. She was there on time even though she had to come in the middle of the night. She was like Just tell us the time and we'll be there whatever time it is and you need not worry. I truly recommend her to every bride out there. Don't even hesitate to book her for your big day. <3 <3
            Thank you Aishu for dolling me up <3"
            img={ashwini}
            name="Ashwini N"
            service="Hairstyling, Makeovers"
          />
          </article>

          <h2 className="text-right px-4"><a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zKvMizc2KagwYLRSNagwTjQ1MjNMNUw1NUlLMU5KsjKoSLQ0SzVKNU-zMDVJMTc1NPUSrkrMVMhNzE7NL0stUiguKU3JzAcAN1oW9g&q=zai+makeover+studio&oq=zai+make&aqs=chrome.1.69i57j46i175i199i512j35i39j0i22i30l2j0i390j69i60l2.3279j0j1&sourceid=chrome&ie=UTF-8" target="__blank" className="font-bold text-secondary hover:text-primary underline underline-offset-4">All Reviews →</a></h2>
          
        </section>
      </article>

{/* Footer */}
      <Footer/>

    </section>
  )
};

export default Home;