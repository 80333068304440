import React from "react";

const Header = ({img, heading}) => {
  return (
      <article className="relative h-72 md:h-96 w-full bg-secondary/50 text-neutral">
        <img src={img} alt="model" className="w-full h-full object-cover absolute mix-blend-overlay" />
        <div className="text-center h-full flex justify-center items-center">
        <h2 className="text-5xl md:text-7xl font-thin tracking-widest uppercase">{heading}</h2>
        </div>
      </article>
  )
};
export default Header;