import React from 'react';
import Link from './Link';
import logo_light from '../imgs/zai_logo_light.png'

const Navbar = () => {

  const hamburgerClick = () => document.querySelector('#mobile_menu').classList.toggle('hidden');
  
  return (
    <section className="">
      <nav className="bg-primary fixed w-full left-0 px-8 md:py4 md:px-24 z-20">
        <div className="flex justify-between items-center text-xs lg:text-sm uppercase">
          
          <i id="myBtn" className="fa-solid fa-bars md:hidden text-lg text-neutral cursor-pointer" onClick={hamburgerClick}></i>

          <Link href='/'><img src={logo_light} alt="" className="w-16 md:w-20 cursor-pointer scale-125"/></Link>
          
          <aside id="desktop_menu" className="hidden md:block">
            <div className="font-light list-none flex gap-4 lg:gap-12">
              <Link href='/' className="py-2 px-4 cursor-pointer text-neutral hover:text-secondary hover:underline underline-offset-4 transition-colors">Home
              </Link>
              <Link href='/about' className="py-2 px-4 cursor-pointer text-neutral hover:text-secondary hover:underline underline-offset-4 transition-colors">About</Link>
              <Link href='/portfolio' className="py-2 px-4 cursor-pointer text-neutral hover:text-secondary hover:underline underline-offset-4 transition-colors">Portfolio</Link>
              <Link href='/contact' className="py-2 px-4 cursor-pointer text-neutral hover:text-secondary hover:underline underline-offset-4 transition-colors ">Contact</Link>
            </div>
          </aside>

          <aside className='hidden md:block '>
            <Link href='/contact'><button className="font-bold border-2 text-xs lg:text-sm uppercase border-secondary px-6 lg:px-8 py-2 text-secondary hover:text-neutral hover:scale-105 hover:bg-secondary transition-all">Hire Us</button></Link>
          </aside>

        </div>

      </nav>
        <aside id="mobile_menu" className="bg-primary hidden md:hidden fixed w-full top-14 right-0 py-6 z-10">
          <div className="space-y-8">
          {/* <i id="" className="fa-solid fa-close text-2xl md:hidden cursor-pointer" onClick={hamburgerClick}></i> */}
            <div className="px-6 font-bold text-neutral text-center text-lg list-none flex flex-col">
              <Link href="/" className="py-4 cursor-pointer hover:text-secondary transition-colors">Home</Link>
              <Link href="/about" className="py-4 cursor-pointer hover:text-secondary transition-colors">About</Link>
              <Link href="/portfolio" className="py-4 cursor-pointer hover:text-secondary transition-colors">Portfolio</Link>
              <Link href="/contact" className="py-4 cursor-pointer hover:text-secondary transition-colors">Contact</Link>

              <Link href='/contact'><button className="font-bold w-full border-2 text-xs lg:text-sm uppercase border-secondary px-6 lg:px-8 py-4 text-secondary hover:text-neutral hover:scale-105 hover:bg-secondary transition-all">Hire Us</button></Link>
            </div>
          </div>
        </aside>

    </section>
  );
};

export default Navbar;