import React from "react";

const Loader = () => {

  const hideLoader = setTimeout(() => {
      document.querySelector('#loader_wrapper').style.display = 'none';
      document.querySelector('.loader').classList.add('hidden');
  }, 2000)

  return (
    <div id="loader_wrapper" className="" onLoad={hideLoader}>
      <span className="loader" onLoad={hideLoader}></span>
    </div>
  )
};

export default Loader;