import React from "react";

// imgs
import img1 from '../imgs/portfolio/editorial/img1.webp';
import img2 from '../imgs/portfolio/editorial/img2.webp';
import img3 from '../imgs/portfolio/editorial/img3.webp';
import img4 from '../imgs/portfolio/editorial/img4.webp';
import img5 from '../imgs/portfolio/editorial/img5.webp';
import img6 from '../imgs/portfolio/editorial/img6.webp';
import img7 from '../imgs/portfolio/editorial/img7.webp';

const Editorial = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7]

  return (
    <div className="grid grid-cols-2 lg:grid-cols-2 gap-6 items-center">
      {images.map(img => <img alt="img" src={img} className="w-5/6 md:w-3/6 mx-auto rounded-lg shadow-2xl shadow-primary/70"/>)}
    </div>
  );
};

export default Editorial;