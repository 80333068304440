import React from "react";
import Header from "./Header";
import Footer from "./Footer"

//Images
import portfolio_header from '../imgs/contact_header.jpg';

const Contact = () => {
  return (
    <section className="font-body pt-16 md:pt-20">
    <Header
      img={portfolio_header}
      heading="Contact Us"
    />
      
    <div className="bg-contact-us-texture bg-no-repeat bg-contain bg-right py-6 sm:py-12 lg:py-24 font-body">
      <div className="max-w-screen-2xl px-4 md:px-8 mx-auto space-y-12">
        {/* <!-- text - start --> */}

          <section className="text-secondary text-center space-y-4 uppercase">
            <h2 className="text-xl lg:text-4xl font-semibold text-secondary">Get In Touch</h2>
            <hr className="border border-secondary w-1/6 md:w-1/12 mx-auto"/>
            <p className="max-w-screen-md text-primary md:text-lg text-center mx-auto">Please fill out the form, we will get back to you as soon as possible!</p>
          </section>

          <form
            action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSco0C1ksTZeSO9mV5965nBnF4oh3gLFWaCT3uxiJkNkpM4MtQ/formResponse"
            className="max-w-screen-md grid sm:grid-cols-2 gap-4 mx-auto"
          >
          <div className="sm:col-span-2">
            <label for="first-name" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">Name*</label>
            <input name="entry.2005620554" placeholder="eg: John Doe" className="w-full bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2" />
          </div>

          <div className="">
            <label for="phone" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">Phone*</label>
            <input name="entry.1121871090" placeholder="eg: 1234567890" className="w-full bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2" />
          </div>

          <div className="">
            <label for="email" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">Email*</label>
            <input name="entry.287258897" placeholder="eg: john@gmail.com" className="w-full bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2" />
          </div>
            
          <div className="sm:col-span-2 flex flex-col justify-left">
            <h1 for="" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">Services*</h1>
              
            <div className="grid grid-cols-2 gap-6">
            
              <div className="form-check">
                <input className="form-check-input rounded-full h-4 w-4 border border-primary transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="entry.1885807406" id="bridal" value="Bridal Makeup"/>
                <label className="form-check-label inline-block text-gray-800" for="bridal">
                  Bridal Makeup
                </label>
              </div>
                
              <div className="form-check">
                <input className="form-check-input rounded-full h-4 w-4 border border-primary transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="entry.1885807406" id="commercial" value="Commercial Makeup"/>
                <label className="form-check-label inline-block text-gray-800" for="commercial">
                  Commercial Makeup
                </label>
              </div>
                
              <div className="form-check">
                <input className="form-check-input rounded-full h-4 w-4 border border-primary  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="entry.1885807406" id="editorial" value="Editorial Makeup"/>
                <label className="form-check-label inline-block text-gray-800" for="editorial">
                  Editorial Makeup
                </label>
              </div>
                
              <div className="form-check">
                <input className="form-check-input rounded-full h-4 w-4 border border-primary  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="entry.1885807406" id="class" value="Makeup Class"/>
                <label className="form-check-label inline-block text-gray-800" for="class">
                  Makeup Class
                </label>
              </div>
                
            </div>
          </div>

          <div className="">
            <label for="date" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">Date of Event*</label>
            <input name="entry.1065046570" placeholder="DD/MM/YYYY" id="date" className="w-full bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2" />
            </div>
            
          <div className="">
            <label for="city" className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest">City of Event*</label>
            <input name="entry.1166974658" placeholder="eg: Pune" id="city" className="w-full bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2" />
          </div>

          <div className="sm:col-span-2">
            <label for="message" className="inline-block font-bold text-primary text-sm sm:text-base mb-2">Message <span className="text-sm text-primary/50 font-normal">(Optional)</span></label>
            <textarea name="entry.839337160" placeholder="Your message to us..." id="message" className="w-full h-24 bg-transparent focus:bg-secondary/10 text-primary border border-primary focus:border-secondary focus:ring ring-secondary rounded outline-none transition duration-100 px-3 py-2"></textarea>
          </div>

          <div className="sm:col-span-2 flex justify-between items-center">
            <button type="submit" className="inline-block bg-secondary hover:bg-secondary/70 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Submit Request</button>

            {/* <span className="text-primary/50 text-sm">*Required</span> */}
          </div>
            <div className="text-primary/50 text-sm"><i class="fa-solid fa-circle-info"></i> If you have any queries <a href="tel:+919080041806" className="font-bold underline underline-offset-4"><em>Call Us </em></a>&#x2197; or <a href="mailto:info@zaimakeoverstudio.com" className="font-bold underline underline-offset-4"><em>Email Us </em></a>&#x2197; </div>
        </form>
        {/* <!-- form - end --> */}
      </div>
    </div>
      
    {/* Footer */}
    <Footer/>
  </section>
  )
};
export default Contact;