import React from "react";
import Link from "./Link";

const Service = ({img, head}) => {
  return (
    <article className="bg-gradient-to-t from-stone-900 via-zinc-500 to-stone-500 w-5/6 sm:w-3/6 md:w-full lg:w-5/6 xl:w-4/6 mx-auto h-[34rem] relative rounded-lg">

      <img src={img} alt="model" className="object-cover absolute mix-blend-overlay w-full h-full rounded-lg"/>

      <div className="text-center top-96 relative">
        <h2 className="text-2xl lg:text-2xl py-4 bg-secondary text-primary uppercase">{head}</h2>

        <Link href='/contact'><button className="font-bold w-3/6 mt-6 py-3 text-sm lg:text-sm border-2 border-neutral hover:border-secondary text-neutral hover:text-primary hover:scale-105 hover:bg-secondary hover:tracking-[4px] transition-all uppercase">Hire Us</button></Link>

      </div>
    </article>
  )
};

export default Service;