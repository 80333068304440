import React from "react";

// images
import img1 from '../imgs/portfolio/bridal/img1.webp';
import img2 from '../imgs/portfolio/bridal/img2.jpeg';
import img3 from '../imgs/portfolio/bridal/img3.jpeg';
import img4 from '../imgs/portfolio/bridal/img4.JPG';
import img5 from '../imgs/portfolio/bridal/img5.jpg';
import img6 from '../imgs/portfolio/bridal/img6.JPG';
import img7 from '../imgs/portfolio/bridal/img7.jpeg';
import img8 from '../imgs/portfolio/bridal/img8.jpeg';
import img9 from '../imgs/portfolio/bridal/img9.jpg';
import img10 from '../imgs/portfolio/bridal/img10.jpeg';
import img11 from '../imgs/portfolio/bridal/img11.jpg';
import img12 from '../imgs/portfolio/bridal/img12.jpeg';
import img13 from '../imgs/portfolio/bridal/img13.JPG';
import img14 from '../imgs/portfolio/bridal/img14.jpeg';
import img15 from '../imgs/portfolio/bridal/img15.JPG';
import img16 from '../imgs/portfolio/bridal/img16.jpeg';
import img17 from '../imgs/portfolio/bridal/img17.jpeg';
import img18 from '../imgs/portfolio/bridal/img18.jpeg';
import img19 from '../imgs/portfolio/bridal/img19.jpeg';
import img20 from '../imgs/portfolio/bridal/img20.jpeg';
import img21 from '../imgs/portfolio/bridal/img21.jpeg';
import img22 from '../imgs/portfolio/bridal/img22.jpeg';
import img23 from '../imgs/portfolio/bridal/img23.jpeg';
import img24 from '../imgs/portfolio/bridal/img24.jpeg';
import img25 from '../imgs/portfolio/bridal/img25.jpeg';
import img26 from '../imgs/portfolio/bridal/img26.jpeg';
import img27 from '../imgs/portfolio/bridal/img27.jpeg';
import img28 from '../imgs/portfolio/bridal/img28.jpeg';
import img29 from '../imgs/portfolio/bridal/img29.jpeg';
import img30 from '../imgs/portfolio/bridal/img30.jpeg';
import img31 from '../imgs/portfolio/bridal/img31.jpeg';
import img32 from '../imgs/portfolio/bridal/img32.jpeg';
import img33 from '../imgs/portfolio/bridal/img33.jpeg';
import img34 from '../imgs/portfolio/bridal/img34.JPG';
import img35 from '../imgs/portfolio/bridal/img35.webp';

const Bridal = () => {

  const images = [img1, img2, img3,img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35]

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 items-center">
      {images.map(img => <img alt="img" src={img} className="w-5/6 md:w-3/6 mx-auto rounded-lg shadow-2xl shadow-primary/70"/>)}
    </div>
  );
};

export default Bridal