import React from "react";

// imgs
import img1 from '../imgs/portfolio/commercial/img1.webp';
import img2 from '../imgs/portfolio/commercial/img2.jpg';
import img3 from '../imgs/portfolio/commercial/img3.jpeg';
import img4 from '../imgs/portfolio/commercial/img4.jpg';
import img5 from '../imgs/portfolio/commercial/img5.jpeg';
import img6 from '../imgs/portfolio/commercial/img6.jpg';
import img7 from '../imgs/portfolio/commercial/img7.jpeg';
import img8 from '../imgs/portfolio/commercial/img8.jpg';
// import img9 from '../imgs/portfolio/commercial/img9.jpg';
// import img10 from '../imgs/portfolio/commercial/img10.jpg';
// import img11 from '../imgs/portfolio/commercial/img11.jpg';
// import img12 from '../imgs/portfolio/commercial/img12.jpg';
import img13 from '../imgs/portfolio/commercial/img13.jpg';
import img14 from '../imgs/portfolio/commercial/img14.jpg';
import img15 from '../imgs/portfolio/commercial/img15.jpg';
import img16 from '../imgs/portfolio/commercial/img16.jpg';
import img17 from '../imgs/portfolio/commercial/img17.jpg';
import img18 from '../imgs/portfolio/commercial/img18.jpg';
import img19 from '../imgs/portfolio/commercial/img19.jpg';
import img20 from '../imgs/portfolio/commercial/img20.jpg';
import img21 from '../imgs/portfolio/commercial/img21.jpg';

const Commercial = () => {

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img13, img14, img15, img16, img17, img18, img19, img20, img21]

  return (
    <div className="grid grid-cols-2 lg:grid-cols-2 gap-6 items-center">
      {images.map(img => <img alt="img" src={img} className="w-5/6 md:w-3/6 mx-auto rounded-lg shadow-2xl shadow-primary/70"/>)}
    </div>
  );
};

export default Commercial