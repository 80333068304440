import React from "react";
// import Link from "./Link";

// Images
import logo_light from '../imgs/zai_logo_light.png'

const Footer = () => {
  return (
    <section className="bg-primary text-neutral pt-12">
      <article className="max-w-screen-2xl mx-auto space-y-4 px-6">
        <div className="text-secondary space-y-6 w-full mx-auto md:flex justify-center items-center">
          <div className="text-center md:text-left md:w-2/6 space-y-4">
            <h2 className="font-light uppercase">Follow Us</h2>
            <div className="text-3xl md:text-2xl space-x-8 md:space-x-4">
              <a href="https://www.instagram.com/zai.makeover.studio/" target="__blank"><i className="fa-brands fa-instagram hover:text-neutral transition-colors"></i></a>
              {/* <a href="/"><i className="fa-brands fa-twitter hover:text-neutral transition-colors"></i></a> */}
              <a href="https://www.facebook.com/zai.makeover.studio" target="__blank"><i className="fa-brands fa-facebook-f hover:text-neutral transition-colors"></i></a>
              <a href="https://www.youtube.com/channel/UC1XivoV3L79nA-z1LuX_Sgg" target="__blank"><i className="fa-brands fa-youtube hover:text-neutral transition-colors"></i></a>
              <a href="https://wa.me/+919080041806?text=Hi%20I%20would%20like%20to%20enquire%20for%20makeup%20booking%20" target="__blank"><i className="fa-brands fa-whatsapp hover:text-neutral transition-colors"></i></a>
            </div>
          </div>

            <hr className="hidden md:block w-20 rotate-90 bg-neutral"/>

          <div className="md:w-2/6">
            <img src={logo_light} alt="zai logo" className="w-4/12 md:w-3/12 mx-auto"/>
          </div>

            <hr className="hidden md:block w-20 rotate-90 bg-neutral"/>

          <div className="md:w-2/6 space-x-2 space-y-4 text-center md:text-right">
            <input type="text" placeholder="Get a quotation..." value="Get a quotation!" className="inline-block py-2 px-4 rounded-md"/>
            <a href="mailto:info@zaimakeoverstudio.com" className="inline-block py-2 px-6 bg-secondary text-neutral rounded-md">Request</a>
          </div>
      </div>
        
          <div className="py-6 text-center tracking-wider space-y-2 md:space-y-0 uppercase text-xs md:text-sm md:flex justify-between">
            <h2>All Rights Reserved</h2>
            <h2>COPYRIGHT Ⓒ 2022 ZAI MAKEOVER STUDIO</h2>
            <h2>Site by <a href="https://varunbhabhra.com/" target="__blank" className="text-secondary hover:text-neutral underline underline-offset-4">Varun Bhabhra <i className="fa-solid fa-link"></i></a></h2>
          </div>
      </article>
    </section>
  )
};
export default Footer;