import React from "react";

const Testimonial = ({text, img, name, service}) => {
  return (
    <article className="text-primary space-y-8 p-6 lg:w-5/6">
      <p className="opacity-60">{text}</p>

      <div className="flex items-center gap-4">
        <img src={img} alt="client" className="w-16 h-16 rounded-full object-cover object-top" />
        <div>
          <h2 className="font-cta font-bold text-2xl">{name}</h2>
          <span className="opacity-80 font-semibold text-sm">{service}</span>
        </div>
      </div>
    </article>
  )
};

export default Testimonial;